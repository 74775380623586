import React, {useState} from 'react'
import {Button, RocketTimer} from '../../components/library'
import LibraryWrapper from './LibraryWrapper'

function rocketTimer() {

    const [launch, setLaunch] = useState(false)

    return (
        <LibraryWrapper>
            <RocketTimer launch={launch} onComplete={() => console.log('DONE')} />
            <Button onClick={(e) => setLaunch(!launch)} inline>{launch ? 'RESET' : 'LAUNCH'}</Button>
        </LibraryWrapper>
    )
}

export default rocketTimer
